import { useEffect, useRef, useState } from "react";
import { Calendar } from "primereact/calendar";

const DateTime = ({ value, onChange, minDate, maxDate, ...props }) => {
  const [state, setState] = useState({
    localDate: null,
    localTime: null,
    errorMessage: "",
  });
  const datePickerRef = useRef(null);
  const lastValidValue = useRef(null);

  const updateState = (updates) => {
    setState((prevState) => ({ ...prevState, ...updates }));
  };

  useEffect(() => {
    if (value) {
      updateState({
        localDate: value,
        localTime: value,
        errorMessage: "",
      });

      lastValidValue.current = value;
    }
  }, [value]);

  const mergeDateAndTime = (date, time) => {
    if (!date && !time) return null;

    const finalDate = date
      ? new Date(date.getTime())
      : lastValidValue.current
        ? new Date(lastValidValue.current)
        : new Date();
    const finalTime = time
      ? new Date(time.getTime())
      : lastValidValue.current
        ? new Date(lastValidValue.current)
        : new Date();

    finalDate.setHours(0, 0, 0, 0);
    finalDate.setHours(finalTime.getHours(), finalTime.getMinutes(), 0, 0);

    return finalDate;
  };

  const isSameDay = (date1, date2) =>
    date1 && date2 && date1.toDateString() === date2.toDateString();

  const isWithinRange = (date) => {
    if (!date) return false;

    if (minDate) {
      if (isSameDay(date, minDate) && date < minDate) return false;
      if (date < minDate) return false;
    }

    if (maxDate) {
      if (isSameDay(date, maxDate) && date > maxDate) return false;
      if (date > maxDate) return false;
    }

    return true;
  };

  const handleDateChange = (newDate, localTime) => {
    const mergedDateTime = mergeDateAndTime(newDate, localTime);
    lastValidValue.current = mergedDateTime;

    if (isWithinRange(mergedDateTime)) {
      updateState({
        localDate: newDate,
        errorMessage: "",
      });

      onChange(mergedDateTime);
    } else {
      updateState({
        localDate: newDate,
        errorMessage: "Date and time are out of range.",
      });
      onChange(null);
    }
  };

  const handleTimeChange = (newTime, localDate) => {
    const mergedDateTime = mergeDateAndTime(localDate, newTime);
    lastValidValue.current = mergedDateTime;

    if (isWithinRange(mergedDateTime)) {
      updateState({
        localTime: newTime,
        errorMessage: "",
      });

      onChange(mergedDateTime);
    } else {
      updateState({
        localTime: newTime,
        errorMessage: "Date and time are out of range.",
      });
      onChange(null);
    }
  };

  const isValidTimeFormat = (time) => {
    const regex = /^(0[1-9]|1[0-2]):[0-5][0-9]\s?([APap][Mm])$/;

    return regex.test(time);
  };
  const toUpperCaseAndDate = (time) => {
    const today = new Date().toISOString().split("T")[0];
    const dateTimeString = `${today} ${time.toUpperCase()}`;
    return new Date(dateTimeString);
  };
  const isValidDateObj = (date) => {
    return date instanceof Date && !isNaN(date?.valueOf());
  };

  const { localDate, localTime, errorMessage } = state;

  return (
    <div className="flex w-full flex-col gap-2">
      {/* Inline Date Picker and Text Input for Date */}
      <div
        className={`flex flex-col gap-1 rounded-lg border bg-slate-50 p-2 ${
          errorMessage ? "border-red-200" : ""
        }`}
      >
        <Calendar
          inline
          ref={datePickerRef}
          value={localDate}
          onChange={(e) => e.value && handleDateChange(e.value, localTime)}
          dateFormat="mm/dd/yy"
          minDate={minDate}
          maxDate={maxDate}
          {...props}
        />
        <Calendar
          value={localDate}
          onChange={(e) => e.value && handleDateChange(e.value, localTime)}
          mask="99/99/9999"
          showOnFocus={false}
          dateFormat="mm/dd/yy"
          placeholder="MM/DD/YYYY"
          className={errorMessage ? "p-invalid" : ""}
          {...props}
        />
      </div>

      {/* Inline Time Picker and Text Input for Time */}
      <div
        className={`flex flex-col gap-1 rounded-lg border bg-slate-50 p-2 ${
          errorMessage ? "border-red-200" : ""
        }`}
      >
        <Calendar
          inline
          value={localTime}
          onChange={(e) => {
            e.value && handleTimeChange(e.value, localDate);
          }}
          timeOnly
          hourFormat="12"
          panelClassName={errorMessage ? "p-invalid" : ""}
          {...props}
        />
        <Calendar
          value={localTime}
          mask="99:99 aa"
          timeOnly
          keepInvalid
          showOnFocus={false}
          hourFormat="12"
          onBlur={() => {
            lastValidValue.current &&
              handleTimeChange(lastValidValue.current, localDate);
          }}
          onChange={(e) => {
            let date = isValidTimeFormat(e.value)
              ? toUpperCaseAndDate(e.value)
              : e.value;

            isValidDateObj(date) && handleTimeChange(date, localDate);
          }}
          className={errorMessage ? "p-invalid" : ""}
          {...props}
        />
      </div>

      {/* Unified Error Message */}
      {errorMessage && (
        <span className="text-sm text-red-500">{errorMessage}</span>
      )}
    </div>
  );
};

export default DateTime;
